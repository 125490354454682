import img1 from "../../../../src/pages/BlogPosts/RandomWalk/img1.png";
import random from "../../../../src/pages/BlogPosts/RandomWalk/random.png";
import randomWalk from "../../../../src/pages/BlogPosts/RandomWalk/randomWalk.gif";
import Tallpic from "../../../../src/components/blogComponents/Tallpic";
import Code from "../../../../src/components/blogComponents/CodeComponent/Code.tsx";
import * as React from 'react';
export default {
  img1,
  random,
  randomWalk,
  Tallpic,
  Code,
  React
};