import model from "../../../../src/pages/BlogPosts/UnityProgress/model.jpg";
import ice from "../../../../src/pages/BlogPosts/UnityProgress/iceberg.png";
import compare from "../../../../src/pages/BlogPosts/UnityProgress/compare.png";
import SideNote from "../../../../src/components/blogComponents/SideNote/SideNote.tsx";
import MediumPic from "../../../../src/components/blogComponents/Pictures/MediumPic";
import * as React from 'react';
export default {
  model,
  ice,
  compare,
  SideNote,
  MediumPic,
  React
};